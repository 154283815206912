//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';
import constant from '~/const';

export default {
  data() {
    return {
      model: {},
      isOld: !process.env.VUE_APP_EXCLUDE_FEATURE.includes('transactions-old'),
      constant,
    };
  },

  computed: {
    ...mapState('settingsTechnicalWork', ['data']),
  },

  watch: {
    data(value) {
      this.$set(this, 'model', { ...value });
    },
  },

  methods: {
    ...mapActions('common', ['confirmAction', 'setGeneralProgress', 'setSuccessNotification']),
    ...mapActions('settingsTechnicalWork', ['loadData', 'updateExchangeOptions']),

    apply() {
      this.confirmAction({
        title: 'Are you sure you want to update exchange options?',
        callback: async () => {
          const { model } = this;
          this.setGeneralProgress(true);
          await this.updateExchangeOptions(model);
          await this.loadData();
          this.setGeneralProgress(false);
          this.setSuccessNotification('Exchange options updated successfully');
        },
        cancelCallback: () => {
          this.$set(this, 'model', { ...this.data });
        },
      });
    },
  },
};
